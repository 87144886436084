import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { IActionDropdownOption } from './interfaces';

@Component({
  selector: 'app-actions-dropdown',
  templateUrl: './actions-dropdown.component.html',
  styleUrls: ['./actions-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionsDropdownComponent {

  @Input() actions: IActionDropdownOption[];
  @Input() triggerTemplate: ElementRef;
  @Input() triggerColor: 'white' | 'black' = 'white';
  @Input() dropdownDisabled = false;

  @Output() action = new EventEmitter<IActionDropdownOption>();

  trackByFn(index: number, action: IActionDropdownOption): string {
    return action.actionType;
  }
}
