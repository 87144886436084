<div class="wrapper">
    <app-common-header [isMobile]="isMobile" [isDesktopDevice]="isDesktopDevice"></app-common-header>
    <section class="live-streams-wrapper">
        <app-sidebar
            *ngIf="service.setValue ==='isOpen' || isDesktopDevice"
            [isMobile]="isMobile"
            [isDesktopDevice]="isDesktopDevice"
        ></app-sidebar>
        <div class="page-wrapper" id="page-wrapper">
            <router-outlet></router-outlet>
        </div>
    </section>
    <app-footer></app-footer>
</div>