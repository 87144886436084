import { NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// components
import { CommonHeaderComponent } from '../../default/shared/components/common-header/common-header.component';
import { FooterComponent } from '../../default/shared/components/footer/footer.component';
import { SidebarComponent } from '../../default/shared/components/sidebar/sidebar.component';
import { BreadcrumbsComponent } from '../../default/shared/components/breadcrumbs/breadcrumbs.component';
import { ImagemanagerpopupComponent } from './popup/ImageManagerPopup/imagemanagerpopup.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductSuccessComponent } from './popup/product-success/product-success.component';
import { LoaderComponent } from './components/loader/loader.component';
import { NodataComponent } from './components/nodata/nodata.component';
import { NumberAcceptModule } from './validation-directives/onlyNumber.module';
import { AppMaterial } from '../../app.material.module';
import { AddCouponComponent } from './popup/add-coupon/add-coupon.component';
import { AddDeliveryComponent } from './popup/add-delivery/add-delivery.component';
import { PipeModule } from './pipe/pipe.module';
import { UpcommingComponent } from './components/upcomming-page/upcomming-page.component';
import { DeletePopupComponent } from './popup/delete-popup/delete-popup.component';
import { CurrencyPipeModule } from './pipe/currency.module';
import { ConfirmationDialogComponent } from './popup/Confirmation-dialog/confirmation-dialog.component';
//import { AddStreamModalComponent } from './popup/add-stream-modal/add-stream-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { CloudinaryModule } from "@cloudinary/ng";
import {GlobalLoaderComponent} from './components/global-loader/global-loader.component';
import {MatChipsModule} from '@angular/material/chips';
import { PagerModule } from './components/pager/pager.module';
import { ActionsDropdownModule } from './components/actions-dropdown/actions-dropdown.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        NumberAcceptModule,
        AppMaterial,
        PipeModule, 
        CurrencyPipeModule,
        NgSelectModule,
        CloudinaryModule,
        MatChipsModule,
        PagerModule,
        ActionsDropdownModule,
    ],
    exports: [
        CommonModule,
        RouterModule,
        NumberAcceptModule,
        CommonHeaderComponent,
        FooterComponent,
        SidebarComponent,
        BreadcrumbsComponent,
        ProductSuccessComponent,
        LoaderComponent,
        NodataComponent,
        AppMaterial,
        PipeModule, CurrencyPipeModule,
        UpcommingComponent,
        GlobalLoaderComponent,
        PagerModule
    ],
    declarations: [
        CommonHeaderComponent,
        FooterComponent,
        SidebarComponent,
        BreadcrumbsComponent,
        ImagemanagerpopupComponent,
        ProductSuccessComponent,
        GlobalLoaderComponent,
        LoaderComponent,
        NodataComponent,
        ConfirmationDialogComponent,
        AddCouponComponent,
        AddDeliveryComponent,
        UpcommingComponent,
        DeletePopupComponent
    ],
    entryComponents: [ImagemanagerpopupComponent, ProductSuccessComponent, AddCouponComponent, AddDeliveryComponent, DeletePopupComponent, ConfirmationDialogComponent],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'en-US' }
    ],
})
export class SharedModule {
}
