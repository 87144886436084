export const environment = {
  production: true,
  baseUrl: 'https://backend.splurky.com/api',
  imageUrl: 'https://backend.splurky.com/api/media/video-preview-s3',
  productUrl: 'https://backend.splurky.com',
  pluginUrl: 'https://backend.splurky.com',
  socUrl: 'https://notification.splurky.com/events/start?channel=',
  labelUrl: 'https://storage.googleapis.com/splurky-assets/',
  cloudName: 'splurky',
  cloudFolder: 'splurky',
  storeProfileUrl : 'https://splurky.com/account/myprofile',
  streamUrl : '',
  videoBucketName: 'dev-blazing-card-agora-streams',
  baseBucketUrl: 's3.amazonaws.com',
  chatUrl: 'https://chat.splurky.com',
  stripePublicKey: 'pk_test_51PkhgURoNstAiYcVPTVnYPKN4jVsuByZfM9IUf48hE8zqJKDfpRSkztFwvsb5SGyjh5KSJr6ihIlw8VEg03rjFBH00dBDCmHKo',
};