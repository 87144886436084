import { Component, Input, OnInit } from '@angular/core';
import { OrderService } from '../../../../core/order/order.service';
import { CommonService } from '../../../../core/common/common.service';
import { menu } from './side-menu';
import { IMenuItem } from '../../interfaces/interface';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {

  collapsedMenuIds = new Set<number>();
  menuItems: IMenuItem[] = menu;
  isActive : boolean = false;
  totalOrder : number = 0;

  @Input() isMobile;
  @Input() isDesktopDevice;

  constructor(
    private service : CommonService,
    private orderService: OrderService,
  ) {}

  ngOnInit() {
    this.getOrderCount();
  }

  getOrderCount() {
    this.orderService.orderCount().subscribe((result) => {
      if(result?.data) {
       this.totalOrder = result.data.totalOrderCount;
      }
    })
  }

  closeSideMenu() {
    this.isActive = true;
    this.service.setValue = '';
    this.service.isClose.next(true);
  }

  toggleMenu(event: MouseEvent, menuItem: IMenuItem): void {
    event.preventDefault();

    const id = menuItem.id;
    if (this.collapsedMenuIds.has(id)) {
      this.collapsedMenuIds.delete(id)
    } else {
      this.collapsedMenuIds.add(id);
    }
  }
}