<div
    class="menu-icon flex flex-ce actions-trigger" 
    [matMenuTriggerFor]="dropdownDisabled ? null : menu"
    [ngClass]="{disabled: dropdownDisabled}"
>
    <ng-container *ngIf="triggerTemplate; else defaultTriggerTemplate">
        <ng-container *ngTemplateOutlet='triggerTemplate'></ng-container>
    </ng-container>
</div>
<mat-menu #menu="matMenu" class="menu-action" xPosition="before">
    <ng-template matMenuContent>
        <button
            *ngFor="let actionItem of actions; trackBy: trackByFn"
            type="button"
            class="menu-item"
            [ngClass]="{disabled: actionItem.disabled}"
            mat-menu-item
            (click)="action.emit(actionItem)"
        >
            <mat-icon>{{ actionItem.icon }}</mat-icon>
            <span>{{ actionItem.title }}</span>
        </button>
    </ng-template>
</mat-menu>

<ng-template #defaultTriggerTemplate="">
    <img
        [src]="triggerColor === 'white' ? '../../../../../../assets/images/tbl-menu.svg' :  '../../../../../../assets/images/menu-white.svg'" 
        alt="actions-icon"
    />
</ng-template>
