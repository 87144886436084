import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CommonService } from '../../../core/common/common.service';
import { fromEvent, Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {

  setValue : string = '';
  deviceInfo : any;
  isDesktopDevice : boolean = true;
  isMobile : boolean = false;
  isTablet : boolean = false;
  innerWindowHeight: number;

  resizeSubscription = Subscription.EMPTY;

  constructor(
    public service : CommonService,
    private deviceService: DeviceDetectorService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {
    this.setValue = this.service.setValue;
    this.epicFunction();
    this.resizeSubscription = fromEvent(window, 'resize').pipe(
      startWith(false)
    ).subscribe(() => this.updateDynamicHeightVariable())
  }

  ngOnDestroy() {
    this.resizeSubscription.unsubscribe();
  }

  epicFunction() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
  }

  private updateDynamicHeightVariable(): void {
    const height = window.innerHeight;

    if (this.innerWindowHeight !== height) {
      this.innerWindowHeight = height;
      this.document.documentElement.style.setProperty('--dynamic-height', `${height}px`);
    }
  }
}